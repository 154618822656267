import { combineReducers } from "redux";
import get from "lodash-es/get";

import { createReducer } from "../../utils";
import {
  SUCCESS,
  FAILURE,
  CLEAR_FAILURE_ERROR,
} from "../../utils/types/create-constants.types";
import { AutocompleteOptions } from "../../components/Form/FormAutocompleteInput";

import {
  userLoginTypes,
  USER_SIGNUP_FIRST_STEP,
  getUserDataTypes,
  getUserDocumentsTypes,
  UserStoreConstants,
  SET_USER_DOCS,
  editUserTypes,
  CLEAR_USER_DATA,
  getUserContactOptionsTypes,
  verifyUserPhoneTypes,
  saveUserNotificationOptionsTypes,
  getAddressByZipCodeTypes,
  getUserTransactionsTypes,
  GET_USER_TRANSACTIONS,
  verifyReferralCodeTypes,
  RESET_REFERRAL_CODE_DATA,
  getUserCardsTypes,
  SET_CARD_FORM,
  SET_SIGNUP_DOCS,
  removeCreditCardTypes,
  createCreditCardTypes,
  userTwoFAGenerateQRTypes,
  userTwoFAVerifyTypes,
  getUserDocumentCheckIdReportTypes,
} from "./user.constants";
import {
  ILoginSuccessAction,
  Token,
  IUserState,
  IUserData,
  ITransaction,
  TwoFAEnabled,
  Verified,
  TwoFAEnforced,
  IUserTwoFAData,
  IVerifySuccessAction,
  IUserDocReport,
  ErrorMessage,
  ILoginFailAction,
} from "./user.types";

const {
  USER_DOCUMENTS,
  USER_CONTACT_OPTIONS,
  AUTOCOMPLETE_ADDRESSES,
  USER_TRANSACTIONS,
  USER_CARDS,
  SIGNUP_DOCUMENTS,
} = UserStoreConstants;

const mockedCards = [
  {
    id: 100101,
    tokenizedCard: "tokenized-card-2",
    lastFourDigits: "xxxx-xxxx-xxxx-2345",
    expirationMonth: "04",
    expirationYear: "20",
    cardholderName: "Artur Test",
    fundingSource: "CREDIT_CARD",
    fundingSourceProcessorType: "CC_SAGE_PAY",
  },
  {
    id: 100102,
    tokenizedCard: "tokenized-card-2",
    lastFourDigits: "xxxx-xxxx-xxxx-6",
    expirationMonth: "01",
    expirationYear: "20",
    cardholderName: "Test Test",
    fundingSource: "CREDIT_CARD",
    fundingSourceProcessorType: "CC_SAGE_PAY",
  },
  {
    id: 100103,
    tokenizedCard: "tokenized-card-2",
    lastFourDigits: "xxxx-xxxx-xxxx-1014",
    expirationMonth: "01",
    expirationYear: "20",
    cardholderName: "Test Test",
    fundingSource: "CREDIT_CARD",
    fundingSourceProcessorType: "CC_SAGE_PAY",
  },
];

const setToken = (_: Token, action: ILoginSuccessAction): Token =>
  action.payload.token;

const setTwoFAEnabled = (
  _: TwoFAEnabled,
  action: ILoginSuccessAction,
): TwoFAEnabled => action.payload.twoFAEnabled;

const setTwoFAEnforced = (
  _: TwoFAEnforced,
  action: ILoginSuccessAction,
): TwoFAEnforced => action.payload.twoFAEnforced;

const setVerified = (_: Verified, action: IVerifySuccessAction): Verified =>
  action.payload.verified;

const setErrorMessage = (
  _: ErrorMessage,
  action: ILoginFailAction,
): ErrorMessage => action.error.message;

const tokenReducer = createReducer<Token>(
  {
    [userLoginTypes[SUCCESS]]: setToken,
    [CLEAR_USER_DATA]: () => "",
  },
  "",
);

const TwoFAEnabledReducer = createReducer<TwoFAEnabled>(
  {
    [userLoginTypes[SUCCESS]]: setTwoFAEnabled,
    [CLEAR_USER_DATA]: () => false,
  },
  false,
);

const TwoFAEnforcedReducer = createReducer<TwoFAEnforced>(
  {
    [userLoginTypes[SUCCESS]]: setTwoFAEnforced,
    [CLEAR_USER_DATA]: () => false,
  },
  false,
);

const TwoVerifiedReducer = createReducer<Verified>(
  {
    [userTwoFAVerifyTypes[SUCCESS]]: setVerified,
    [CLEAR_USER_DATA]: () => false,
  },
  false,
);

const requestErrorReducer = createReducer<ErrorMessage>(
  {
    [userLoginTypes[FAILURE]]: setErrorMessage,
    [userLoginTypes[CLEAR_FAILURE_ERROR]]: () => "",
  },
  "",
);

const userDocumentsReducer = createReducer<any | []>(
  {
    [getUserDocumentsTypes[SUCCESS]]: (state, { payload }) =>
      payload.userDocumentDetails,
    [SET_USER_DOCS]: (state, { payload }) => payload[USER_DOCUMENTS],
  },
  [],
);

const userDataReducer = createReducer<IUserData | {}>(
  {
    /****  signup */
    [USER_SIGNUP_FIRST_STEP]: (_, { payload }) => payload,
    /****  **********/

    [editUserTypes[SUCCESS]]: (_, { payload }) => ({
      ...payload.userData,
      ...payload,
      phone: get(payload, "phone") || get(payload, "phoneNumber"), // TODO: use one name. need to be fixed on back-end
      phoneNumber: undefined,
    }),
    [userLoginTypes[SUCCESS]]: (_, { payload }) => ({
      ...payload.userData,
      userId: payload.userId,
    }), // signin
    [getUserDataTypes[SUCCESS]]: (_, { payload }) => ({
      ...payload.userData,
      userId: payload.userId,
      // country: "FR",
    }), // get userData
    [verifyUserPhoneTypes[SUCCESS]]: state => ({
      ...state,
      phoneVerified: true,
    }),
    [verifyUserPhoneTypes[FAILURE]]: state => ({
      ...state,
      phoneVerified: false,
    }),
    [CLEAR_USER_DATA]: () => ({}),
  },
  {},
);

const userTwoFADataReducer = createReducer<IUserTwoFAData | {}>(
  {
    [userTwoFAGenerateQRTypes[SUCCESS]]: (_, { payload }) => ({
      ...payload.userTwoFAData,
      ...payload,
    }),
    [CLEAR_USER_DATA]: () => ({}),
  },
  {},
);

const updateIdDataPanelDisplayedReducer = createReducer<boolean>(
  {
    [userLoginTypes[SUCCESS]]: (_, { payload }) =>
      payload.userData.updateIdDataPanelDisplayed,
    [CLEAR_USER_DATA]: () => false,
  },
  false,
);

const getUserDocumentCheckIdReportReducer = createReducer<IUserDocReport | {}>(
  {
    [getUserDocumentCheckIdReportTypes[SUCCESS]]: (_, { payload }) =>
      payload.report,
    [CLEAR_USER_DATA]: () => ({}),
  },
  {},
);

const userContactOptionsReducer = createReducer<string[]>(
  {
    [getUserContactOptionsTypes[SUCCESS]]: (_, { payload }) =>
      payload[USER_CONTACT_OPTIONS],
    [saveUserNotificationOptionsTypes[SUCCESS]]: (_, { payload }) =>
      payload[USER_CONTACT_OPTIONS],
    [getUserContactOptionsTypes[FAILURE]]: () => [],
  },
  [],
);

const autocompleteOptionsReducer = createReducer<AutocompleteOptions>(
  {
    [getAddressByZipCodeTypes[SUCCESS]]: (_, { payload }) => payload.addresses,
  },
  [],
);

export const getUserTransactionsReducer = createReducer<any>(
  {
    [getUserTransactionsTypes[SUCCESS]]: (_, { payload }) => ({
      transactionsList: payload.reportResult,
      latestTransaction: get(payload, "reportResult[0]"),
    }),
  },
  {},
);

export const getUserCardsReducer = createReducer<any>(
  {
    [getUserCardsTypes[SUCCESS]]: (_, { payload: { userCards } }) => {
      return userCards;
    },
    // [createCreditCardTypes[SUCCESS]]: (state, { card }) => state.concat([card]), // Re-fetch credit cards from server
    // [removeCreditCardTypes[SUCCESS]]: (state, { card }) =>
    //   state.filter((record: any) => record.id !== card.id),
  },
  [],
);

export const signupReferralCodeReducer = createReducer<any>(
  {
    [verifyReferralCodeTypes[SUCCESS]]: (_, { payload }) => payload,
    [verifyReferralCodeTypes[FAILURE]]: () => ({ valid: false }),
    [RESET_REFERRAL_CODE_DATA]: () => null,
  },
  null,
);

export const creditCardForm = createReducer<any>(
  {
    [SET_CARD_FORM]: (state, { form }) => form,
    [createCreditCardTypes[SUCCESS]]: () => false,
  },
  null,
);

export const signupDocuments = createReducer<any>(
  {
    [SET_SIGNUP_DOCS]: (state, { payload }) => payload[SIGNUP_DOCUMENTS],
  },
  null,
);

export default combineReducers<IUserState>({
  creditCardForm,
  signupDocuments,
  token: tokenReducer,
  twoFAEnabled: TwoFAEnabledReducer,
  twoFAEnforced: TwoFAEnforcedReducer,
  verified: TwoVerifiedReducer,
  requestError: requestErrorReducer,
  userData: userDataReducer,
  userTwoFAData: userTwoFADataReducer,
  updateIdDataPanelDisplayed: updateIdDataPanelDisplayedReducer,
  getUserDocumentCheckIdReport: getUserDocumentCheckIdReportReducer,
  userDocuments: userDocumentsReducer,
  [USER_CONTACT_OPTIONS]: userContactOptionsReducer,
  [AUTOCOMPLETE_ADDRESSES]: autocompleteOptionsReducer,
  [USER_TRANSACTIONS]: getUserTransactionsReducer,
  signupReferralCode: signupReferralCodeReducer,
  [USER_CARDS]: getUserCardsReducer,
});
