import organizationsConfig from "../../src/organizations.conf.json";
const { id: organizationId, name: organizationName } = organizationsConfig[
  process.env.REACT_APP_NAME
];

export default {
  port: process.env.PORT || 3000,
  apiBase: process.env.API_HOST || "https://api.server.ulagent.com",
  checkoutApiUrl: "https://api.checkout.com",
  gatewayMerchantId: process.env.GATEWAY_MERCHANT_ID || "pk_04d12105-74a2-46ac-8c7d-ce7d2cfff302",
  organizationId,
  organizationName,
  name: process.env.REACT_APP_ENV,
  payosuKey: "082976c7-3c97-49a0-8cfb-4a6f6962c02c",
  payosuScript: "//widget.live.payosu.com/index.js",
  country: "FR",
  password:"6WScDw3LTz20YWf5@H%pqiA&AjbFRS@z",
  secret:"BuOKKrtoVGAm9gVzM2642uJRjbLh9K3Q"
};
