import { createActions } from "../../utils";

import {
  getOrganizationDataTypes,
  getMobileConfigTypes,
  getRecipientRelationshipsTypes,
  getPurposesOfTransferTypes,
  getSourcesOfFundsTypes,
  getDocTypes,
  getIdDocTypes,
  SETUP_SECURE_TRADING,
  getListOfAgentsTypes,
} from "./organization.constants";

export const getOrganizationDataActions = createActions(
  getOrganizationDataTypes,
);

export const getRecipientRelationshipsActions = createActions(
  getRecipientRelationshipsTypes,
);

export const getPurposesOfTransferActions = createActions(
  getPurposesOfTransferTypes,
);

export const getDocTypesActions = createActions(getDocTypes);
export const getIdDocTypesActions = createActions(getIdDocTypes);

export const getSourcesOfFundsActions = createActions(getSourcesOfFundsTypes);

export const getMobileConfigActions = createActions(getMobileConfigTypes);
export const setupSecureTradingAction = (payload: {
  submitFormCallback: () => void;
  errorCallback: () => void;
}) => ({
  type: SETUP_SECURE_TRADING,
  payload,
});

export const getListOfAgentsAction = createActions(getListOfAgentsTypes);
